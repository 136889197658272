import { createStore } from 'vuex'

export default createStore({
  state: {
    isMobile: false
  },
  getters: {
  },
  mutations: {
    updataIsMoble(
      state, isMobile
    ) {
      state.isMobile = isMobile
    }
  },
  actions: {
  },
  modules: {
  }
})
