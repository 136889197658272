<template>
  <div class="footer-warp">
    <div class="info">
      版权所有：泸州市邓氏土特产品有限公司 厂址：泸州市江阳区况场街道游湾村3社 备案号：蜀ICP备13024849号
    </div>
    <div class="info">电话：（0830）2299068 2750866 传真：(0830)2282768</div>
  </div>
</template>
<style scoped lang="scss">
.footer-warp {
  width: 100%;
  background: #fff;
  padding: 24px 0 12px;
  text-align: center;

  .info {
    margin-bottom: 12px;
  }


}
</style>