const culture = {
  path: "/about",
  name: "about",
  component: () =>
    import(/* webpackChunkName: "about" */ "@/views/about/Index.vue"),
  children: [
    {
      path: "shopDetails/:shop",
      name: "shopDetails",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/about/ShopDetails.vue"),
    },
  ],
};
export default culture;
