<template>
    <div v-if="isMobile" class="in_menu" @click="onMenu">
        <div :class="['nav_phone_btn', { 'visible_nav': isMenuOn }]">
            <span></span>
        </div>
    </div>
    <ul v-else class="menu-list">
        <menu-Item v-for="(item, index) in menuList" :index="item.route" :key="index" :name="item.name"
            :menuRoute="item.route" :children="item.childrens" :isRouter="true" />
    </ul>
    <div v-if="isMobile">
        <ul :class="['phoneMenu', { 'on': isMobile && isMenuOn }]">
            <menu-Item v-for="(item, index) in menuList" :index="item.route" :key="index" :name="item.name"
                :menuRoute="item.route" :children="item.childrens" :isRouter="true" @closeMenuOn="onMenu" />
        </ul>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import MenuItem from './MenuItem.vue'
const store = useStore()
const isMobile = store.state.isMobile
const isMenuOn = ref(false)
const menuList = ref([{
    name: '首页',
    index: 'home',
    route: '/'
}, {
    name: '新闻资讯',
    index: 'news',
    route: '/news'
}, {
    name: '历史文化',
    index: 'culture',
    childrens: [
        // {
        //   name: '邓桂非遗',
        //   index: 'ICH',
        //   route: '/culture/ICH',
        // },
        {
            name: '公司资质',
            index: 'corporate',
            route: '/culture/corporate',
        },
        {
            name: '生产基地',
            index: 'production',
            route: '/culture/production',
        },
        {
            name: '桂圆文化',
            index: 'longan',
            route: '/culture/longan',
        },
        // {
        //   name: '荔枝文化',
        //   index: 'lichee',
        //   route: '/culture/lichee',
        // },
        {
            name: '生产过程',
            index: 'generate',
            route: '/culture/generate',
        }
    ]
}, {
    name: '品牌产品',
    index: 'brand',
    childrens: [{
        name: '邓氏桂圆系列',
        index: 'dsgy',
        route: '/brand/dsgy',
    }, {
        name: '邓桂桂圆系列',
        index: 'dggy',
        route: '/brand/dggy',
    }, {
        name: '邓桂精品系列',
        index: 'boutique',
        route: '/brand/boutique',
    }, {
        name: '盛康宝',
        index: 'skb',
        route: '/brand/skb',
    }, {
        name: '张坝桂圆',
        index: 'zbgy',
        route: '/brand/zbgy',
    }, {
        name: '邓桂荔枝',
        index: 'dglz',
        route: '/brand/dglz',
    }, {
        name: '散装系列',
        index: 'szSeries',
        route: '/brand/szSeries',
    }, {
        name: '望康系列',
        index: 'wkSeries',
        route: '/brand/wkSeries',
    }, {
        name: '桂圆肉系列',
        index: 'gyrSeries',
        route: '/brand/gyrSeries',
    }, {
        name: '其它',
        index: 'other',
        route: '/brand/other',
    }]

}, {
    name: '关于我们',
    index: 'about',
    route: '/about'
}, {
    name: '邓桂商城',
    index: 'mall',
    route: '/mall'
}])
const onMenu = () => {
    isMenuOn.value = !isMenuOn.value
}
</script>

<style lang="scss" scoped>
.in_menu {
    position: absolute;
    width: 30px;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
}

.nav_phone_btn {
    display: block;
    position: relative;
    cursor: pointer;
    margin-top: 0px;
    height: 20px;

    & span {
        position: absolute;
        font-size: 0;
        width: 28px;
        height: 2px;
        background-color: #484848;
        display: inline-block;
        transition: all 250ms ease-out;
        top: 10px;

        &::before {
            top: -10px;
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #484848;
            left: 0px;
            transition: all 250ms ease-out;
            -webkit-transition: all 250ms ease-out;
        }

        &::after {
            top: 10px;
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #484848;
            left: 0px;
            transition: all 250ms ease-out;
            -webkit-transition: all 250ms ease-out;
        }
    }
}

.visible_nav.nav_phone_btn span {
    background-color: transparent;
}

.visible_nav.nav_phone_btn span:before {
    left: 0px;
    top: 0px;
    transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
}

.visible_nav.nav_phone_btn span:after {
    top: 0px;
    left: 0px;
    transform: rotateZ(-45deg);
    -webkit-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
}

.menu-list {
    display: flex;
}



.phoneMenu {
    overflow: auto;
    box-sizing: border-box;
    position: fixed;
    width: 50%;
    height: calc(100% - 60px);
    background: #fff;
    top: 60px;
    right: 0;
    z-index: 600;
    transform: translateX(100%);
    transition: all 250ms ease-out;



    &.on {
        position: fixed;
        transform: translateX(0%);
        -wekit-transform: translateX(0%);
        width: 50%;
        border-left: 3px solid #E40000;
        border-top: 3px solid #E40000;

    }
}
</style>