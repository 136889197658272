const culture = {
  path: "/culture",
  name: "culture",
  component: () =>
    import(/* webpackChunkName: "culture" */ "@/views/culture/Index.vue"),
  children: [
    {
      path: "ICH",
      name: "ICH",
      mate: { title: "邓桂非遗" },
      component: () =>
        import(/* webpackChunkName: "culture" */ "@/views/culture/ICH.vue"),
    },
    {
      path: "production",
      name: "production",
      mate: { title: "生产基地" },
      component: () =>
        import(
          /* webpackChunkName: "culture" */ "@/views/culture/Production.vue"
        ),
    },
    {
      path: "corporate",
      name: "corporate",
      mate: { title: "企业资质" },
      component: () =>
        import(
          /* webpackChunkName: "culture" */ "@/views/culture/Corporate.vue"
        ),
    },
    {
      path: "longan",
      name: "longan",
      mate: { title: "桂圆文化" },
      component: () =>
        import(/* webpackChunkName: "culture" */ "@/views/culture/Longan.vue"),
    },
    {
      path: "lichee",
      name: "lichee",
      mate: { title: "荔枝文化" },
      component: () =>
        import(/* webpackChunkName: "culture" */ "@/views/culture/Lichee.vue"),
    },
    {
      path: "Generate",
      name: "Generate",
      mate: { title: "生成过程" },
      component: () =>
        import(/* webpackChunkName: "culture" */ "@/views/culture/Generate.vue"),
    },
  ],
};
export default culture;
