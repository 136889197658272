import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/Index.vue";
import culture from "./modules/culture";
import about from "./modules/about";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "index",
    component: HomeView,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "news" */ "@/views/home/Index.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () =>
          import(/* webpackChunkName: "news" */ "@/views/news/Index.vue"),
      },
      culture,
      {
        path: "/brand/:type",
        name: "brand",
        component: () =>
          import(/* webpackChunkName: "brand" */ "@/views/brand/Index.vue"),
      },
      about,
      {
        path: "/mall",
        name: "mall",
        component: () =>
            import(/* webpackChunkName: "news" */ "@/views/mall/Index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const homeWrap = document.getElementsByClassName("home-wrap")[0];
  if (homeWrap) {
    homeWrap.scroll(0, 0);
  }
  next();
});

export default router;
